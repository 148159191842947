/*   REACT   */
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
/*   STYLE   */
import "./App.css";

import Header from "./components/Header";
import Layout from "./components/Layout";
/*   LOADING SCREEN   */
import LoadingScreen from "./components/LoadingScreen";

export default function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <>
      {loading === false ? (
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<Layout />} />
          </Route>
        </Routes>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
