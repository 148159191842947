import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import { ReactComponent as Dribble } from "../assets/Dribble.svg";
import { ReactComponent as Linkedin } from "../assets/Linkedin.svg";
import { ReactComponent as Behance } from "../assets/Behance.svg";
import { ReactComponent as Top } from "../assets/Top.svg";

export default function Footer() {
  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <li>
        <a href={`mailto:${email}${params}`}>{children}</a>
      </li>
    );
  };
  return (
    <footer>
      <a
        href="https://www.linkedin.com/in/mattia-tisi/"
        target="_blank"
        rel="noreferrer"
        aria-label="Linkedin Mattia Tisi"
      >
        <Linkedin />
      </a>
      <a
        href="https://www.behance.net/mattiatisiux"
        target="_blank"
        rel="noreferrer"
        aria-label="Behance Mattia Tisi"
      >
        <Behance />
      </a>
      <a
        href="https://dribbble.com/MattiaTisi"
        target="_blank"
        rel="noreferrer"
        aria-label="Dribble Mattia Tisi"
      >
        <Dribble />
      </a>
      <Mailto email="tisi.mattia98@gmail.com">EMAIL</Mailto>{" "}
      <HashLink smooth to="#top">
        <Top />
      </HashLink>
    </footer>
  );
}
