import Logo from "./Logo";

export default function LoadingScreen() {
  return (
    <>
      <div className="loadingLayout"> </div>
      <div className="center fadeOut">
        <Logo />
      </div>
    </>
  );
}
