import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";

export const ContactForm = () => {
  const form = useRef();

  const [mailerState, setMailerState] = useState({
    heading: "Get in touch!",
    name: "",
    email: "",
    message: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6w8ssxq",
        "template_nxcbq1f",
        form.current,
        "J_p3cS0W2mE_yM2mM"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMailerState({
            heading: "Thank You!",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <h1 onChange={handleStateChange}>{mailerState.heading}</h1>

      <input
        placeholder="Name"
        name="name"
        required
        pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"
      />
      <input
        placeholder="Email"
        name="email"
        required
        type="email"
        id="email"
      />
      <textarea placeholder="Message" name="message" required />
      <button> Send Message</button>
    </form>
  );
};
