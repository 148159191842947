import React from "react";
import { useInView } from "react-intersection-observer";

export default function Projects() {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  return (
    <section id="projects" className={inView ? "fadeIn" : ""} ref={ref}>
      <h1>Projects</h1>
      <div className="projects">
        <a
          href="https://www.behance.net/gallery/151934573/Amazon-UX-Case-Study"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <div className="project">
            <article>
              <div className="projectImage">
                <img
                  alt="Amazon Case Study by Mattia Tisi Cover"
                  src={require("../assets/AmazonCover.jpg")}
                />
              </div>
              <p>
                Amazon
                <br />
                Case Study
              </p>
            </article>
          </div>
        </a>
        <a
          href="https://www.behance.net/gallery/151071437/Idealista-Redesign"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <div className="project">
            <article>
              <div className="projectImage">
                <img
                  alt="Idealista Redesign by Mattia Tisi Cover"
                  src={require("../assets/Idealista.jpg")}
                />{" "}
              </div>
              <p>
                Idealista
                <br />
                Redesign
              </p>
            </article>
          </div>
        </a>

        <a
          href="https://www.behance.net/gallery/149751225/Mattia-Tisi-UX-Designer-Branding-Identity"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <div className="project">
            <article>
              <div className="projectImage">
                <img
                  alt="Personal Branding Mattia Tisi Cover"
                  src={require("../assets/Branding.jpg")}
                />{" "}
              </div>
              <p>
                Personal
                <br />
                Branding{" "}
              </p>
            </article>
          </div>
        </a>
      </div>
    </section>
  );
}
