import Contacts from "./Contacts";
import Home from "./Home";
import Projects from "./Projects";
import Skills from "./Skills";

export default function Layout() {
  return (
    <main>
      {" "}
      <Home />
      <Skills />
      <Projects />
      <Contacts />
    </main>
  );
}
