import React from "react";
import { useInView } from "react-intersection-observer";

export default function Home() {
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  return (
    <section id="top" className={inView ? "fadeIn" : ""} ref={ref}>
      {" "}
      <h1>Mattia Tisi</h1>
      <h2>
        UX & Web <br />
        Designer
      </h2>
      <p>
        <b>Hi I'm Mattia Tisi,</b> an Italian <b>Junior UX & Web Designer.</b>{" "}
        <br />
        <br />
        <b>Thanks to</b> the union of <b>these skills</b> I'm able to{" "}
        <b>create websites</b> and design Mobile App <b>that follow </b>the{" "}
        <b>UX Guidelines,</b> are<b> Responsive Design</b> and{" "}
        <b>SEO Friendly.</b>
        <br />
        <br />
        My <b>style</b> follows the <b>philosophy of "less is more"</b>, in
        order to create a clean and easily accessible website.
        <br />
        <br />{" "}
        <div className="hidden">
          {" "}
          Thanks to my studies in IT,
          <b>
            {" "}
            I'm able to learn new frameworks and programming language.
          </b>{" "}
        </div>
      </p>
    </section>
  );
}
