import React from "react";
import { useInView } from "react-intersection-observer";
import Footer from "./Footer";
import { ContactForm } from "./ContactForm";
export default function Contacts() {
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  return (
    <section id="contacts" className={inView ? "fadeIn" : ""} ref={ref}>
      <div className="form">
        <ContactForm />
        <Footer />
      </div>
    </section>
  );
}
