import React from "react";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useInView } from "react-intersection-observer";
import { ReactComponent as MenuHamburger } from "../assets/MenuHamburger.svg";

export default function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  // run this function from an event handler or an effect to execute scroll
  return (
    <>
      <header className={inView ? "fadeIn" : ""} ref={ref}>
        <svg
          width="12vh"
          height="8vh"
          id="Livello_2"
          data-name="Livello 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2569.94 1284.62"
          className="logo"
        >
          <defs />
          <path
            className="cls-1"
            d="M1221,1285c-18,0-34-6.37-45.53-16.83a55.44,55.44,0,0,1-18.52-41.38V248.06l-231.15,312L694.6,872a61.28,61.28,0,0,1-23,18.88,67.4,67.4,0,0,1-29,6.59,72.7,72.7,0,0,1-30.77-6.59A60.18,60.18,0,0,1,588.52,872l-230.15-312-230.15-312v978.73c.26,38-31.88,57.13-64,57.21S-.09,1265.09.13,1226.78V58.86c-1.39-27.71,20-48.11,45.58-55.48S101,1.69,116.21,26.12L379.39,380.86,642.56,735.6,904.74,380.86,1166.92,26.12C1183,2.62,1212.52-3.7,1238,3.1s47,26.75,47,55.77V1226.79c0,16.37-7.5,30.92-19.26,41.38A67.7,67.7,0,0,1,1221,1285Z"
            transform="translate(-0.06 -0.38)"
          />
          <path
            className="cls-1"
            d="M1855.88-15.29h120.42a0,0,0,0,1,0,0V1246a46.51,46.51,0,0,1-46.51,46.51h-27.4a46.51,46.51,0,0,1-46.51-46.51V-15.29A0,0,0,0,1,1855.88-15.29Z"
            transform="translate(1277.4 2554.33) rotate(-90)"
          />
        </svg>
        <nav>
          <button
            type="button"
            data-toggle="collapse"
            data-target="#hamburger"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {/* icon from Heroicons.com */}
            <MenuHamburger />
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul>
              <li>
                <HashLink
                  onClick={() => {
                    setIsNavExpanded(false);
                  }}
                  to="#top"
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  onClick={() => {
                    setIsNavExpanded(false);
                  }}
                  to="#skills"
                >
                  Skills
                </HashLink>
              </li>
              <li>
                <HashLink
                  onClick={() => {
                    setIsNavExpanded(false);
                  }}
                  to="#projects"
                >
                  Projects
                </HashLink>
              </li>
              <li>
                <HashLink
                  onClick={() => {
                    setIsNavExpanded(false);
                  }}
                  to="#contacts"
                >
                  Contacts
                </HashLink>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <Outlet />
    </>
  );
}
